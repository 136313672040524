import React, { useState, useEffect, useCallback } from "react";
import TablePageBackground from "../controls/PageLayout/TablePageBackground";
import {
  LeftControls,
  RightControls,
  TableControls,
} from "../controls/PageLayout/TableControls";
import { PrintButton } from "../common/buttons";
import { useDispatch, useSelector } from "react-redux";
import TableNavigation from "../controls/PageLayout/TableNavigation";
import { LimitSelect } from "../common/customselect";
import { createLPOSales } from "../../storage/lpo";
import LPOService from "../../services/360station/lpo";
import {
  DispensedLPODesktopTable,
  DispensedLPOMobileTable,
} from "../tables/dispensedlpo";
import ShiftSelect from "../common/shift";
import DateRangeLib from "../common/DatePickerLib";
import GenerateReports from "../Modals/reports";

const mobile = window.matchMedia("(max-width: 600px)");

const columns = [
  "S/N",
  "Date",
  "Product",
  "Litres",
  "Rate",
  "Amount",
  "Station",
  "Reference",
];

const ProductsDispensed = () => {
  const dispatch = useDispatch();
  const lpos = useSelector((state) => state.lpo.lpoSales);
  const type = useSelector((state) => state.lpo.dispensed);
  const singleLPO = useSelector((state) => state.lpo.singleLPO);
  const currentDate = useSelector((state) => state.dashboard.dateRange);
  const salesShift = useSelector((state) => state.dailysales.salesShift);

  const [entries, setEntries] = useState(10);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(15);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [prints, setPrints] = useState(false);

  const getDispensed = useCallback((id, date, skip, salesShift) => {
    refresh(id, date, skip, salesShift);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getDispensed("None", currentDate, skip, salesShift);
  }, [getDispensed, currentDate, skip, salesShift]);

  const refresh = (id, date, skip, salesShift, limit = 15) => {
    setLoading(true);

    const payload = {
      skip: skip * limit,
      limit: limit,
      lpoID: singleLPO?._id,
      organisationID: singleLPO?.organizationID,
      productType: type,
      date: date,
      shift: salesShift,
    };

    LPOService.dispensed(payload)
      .then((data) => {
        dispatch(createLPOSales(data.lpo.lpo));
        setTotal(data.lpo.count);
      })
      .then(() => {
        setLoading(false);
      });
  };

  const entriesMenu = (value, limit) => {
    setEntries(value);
    setLimit(limit);
    refresh("None", currentDate, skip, salesShift, limit);
  };

  const printReport = () => {
    // if (!getPerm("5")) return swal("Warning!", "Permission denied", "info");
    setPrints(true);
  };

  const desktopTableData = {
    columns: columns,
    tablePrints: printReport,
    allOutlets: lpos,
    loading: loading,
  };

  const mobileTableData = {
    allOutlets: lpos,
    loading: loading,
  };

  return (
    <React.Fragment>
      <TablePageBackground>
        {mobile.matches || (
          <TableControls>
            <LeftControls>
              <LimitSelect entries={entries} entriesMenu={entriesMenu} />
              <ShiftSelect ml={"10px"} />
            </LeftControls>
            <RightControls>
              <DateRangeLib mt={mobile.matches ? "10px" : "0px"} />
              <PrintButton callback={printReport} />
            </RightControls>
          </TableControls>
        )}

        {mobile.matches && (
          <TableControls mt={"10px"}>
            <LeftControls>
              <DateRangeLib />
            </LeftControls>
            <RightControls></RightControls>
          </TableControls>
        )}

        {mobile.matches ? (
          <DispensedLPOMobileTable data={mobileTableData} />
        ) : (
          <DispensedLPODesktopTable data={desktopTableData} />
        )}

        <TableNavigation
          skip={skip}
          limit={limit}
          total={total}
          setSkip={setSkip}
          updateDate={currentDate}
          callback={refresh}
        />
      </TablePageBackground>
      {prints && (
        <GenerateReports
          open={prints}
          close={setPrints}
          section={"lposales"}
          data={lpos}
        />
      )}
    </React.Fragment>
  );
};

export default ProductsDispensed;
