import React from "react";
import "../../../styles/landing/feature.scss";

const FeatureHero = () => {
  return (
    <React.Fragment>
      <div className="feature-container">
        <div className="header">Features</div>
        <div className="sec-header">
          Our Expansive network of licensed specialist offers wide range of
          service
        </div>
        <div className="content-header">
          360 Station offers an extensive range of services designed to
          revolutionize the way filling stations are managed. Our comprehensive
          software solution empowers filling station owners and business
          managers to oversee, track, and manage all activities transparently
          and efficiently. By leveraging our advanced technology, you can
          streamline operations, increase productivity, and enhance
          profitability.
        </div>
      </div>
    </React.Fragment>
  );
};

export default FeatureHero;
