const data = {
  dashboard: {
    permissions: {
      0: "selecting a single station",
      1: "selecting all stations",
      2: "Selecting a date range.",
      3: "viewing organisational staffs on a single station",
      4: "viewing organisational staffs on all stations",
      5: "viewing total sales",
      6: "viewing tanks and pumps(assets)",
      7: "viewing supply page",
      8: "viewing expenses",
      9: "viewing incoming order",
      10: "selecting top performing stations",
    },
  },

  dailySales: {
    permissions: {
      0: "selecting a single station",
      1: "selecting all stations",
      2: "viewing comprehensive reports",
      3: "changing daily sales date",
      4: "viewing task list",
      5: "viewing daily supllies",
      6: "viewing daily payments",
      7: "viewing daily lpo sales",
      8: "viewing daily incoming order",
      9: "Allow users to print report on comprehensive report page",
      10: "Allow users to view reports from other dates",
      11: "Users to view and update supply",
      12: "users to update pump sales",
      13: "users to update return to tank",
      14: "users to update LPO",
      15: "users to update expenses",
      16: "users to update payments",
      17: "users to update dipping",
    },
  },

  station: {
    permissions: {
      0: "Creating stations",
      1: "Viewing tanks",
      2: "Creating tanks",
      3: "Creating pumps",
      4: "Viewing pumps",
      5: "Editing tanks",
      6: "Deleting tanks",
      7: "Editing pumps",
      8: "Deleting pumps",
    },
  },

  recordsales: {
    permissions: {
      0: "Selecting a single station",
      1: "Selecting all stations",
      2: "Selecting date",
      3: "Saving pump update",
      4: "Saving return to tank",
      5: "Saving LPO",
      6: "Saving expenses",
      7: "Saving payments",
      8: "Saving dipping",
    },
  },

  analysis: {
    permissions: {
      0: "Selecting a single station",
      1: "Selecting all stations",
      2: "Changing cost price",
      3: "Changing selling price",
      4: "Viewing expenses",
      5: "Viewing payments",
      6: "Selecting date range",
    },
  },

  payments: {
    permissions: {
      0: "Selecting a single station",
      1: "Selecting all stations",
      2: "Payment confirmation function",
    },
  },

  expenses: {
    permissions: {
      0: "Selecting a single station",
      1: "Selecting all stations",
    },
  },

  lpo: {
    permissions: {
      0: "Selecting a single station",
      1: "Selecting all stations",
      2: "Creating new LPO",
      3: "Viewing lpo list",
      4: "Editing LPO rates",
      5: "Print lpo record",
    },
  },

  productorder: {
    permissions: {
      0: "Selecting a single station",
      1: "Selecting all stations",
      2: "Creating new product order",
      3: "Print product order record",
    },
  },

  incomingorder: {
    permissions: {
      0: "Selecting a single station",
      1: "Selecting all stations",
      2: "Creating new incoming order",
      3: "Print incoming order record",
    },
  },

  supply: {
    permissions: {
      0: "Selecting a single station",
      1: "Selecting all stations",
      2: "Creating new supply",
      3: "Print supply record",
    },
  },

  regulatory: {
    permissions: {
      0: "Selecting a single station",
      1: "Selecting all stations",
      2: "Registering a payment",
      3: "Print payment record",
    },
  },

  tankupdate: {
    permissions: {
      0: "Selecting a single station",
      1: "Selecting all stations",
      2: "Print tank update record",
    },
  },

  hr: {
    permissions: {
      0: "Selecting a single station for employess",
      1: "Selecting all stations for employees",
      3: "Create new employees",
      4: "view all employees based on user types",
      5: "Print employee list",
      6: "Selecting a single station for salary",
      7: "Selecting all stations for salary",
      8: "Create new salary",
      9: "print report on salary",
      10: "Selecting a single station for query",
      11: "Selecting all stations for query",
      12: "Create new query",
      13: "print report on query",
      14: "Selecting a single station for attendance",
      15: "Selecting all stations for attendance",
      16: "Post new attendance",
      17: "print report on attendance",
    },
  },

  settings: {
    permissions: {
      0: "Selecting a single station",
      1: "Selecting all stations",
      2: "Update my station",
      3: "Change permission enabled for a user",
      4: "Change appearance",
      5: "Change logo",
      6: "Change password",
      7: "Change email",
      8: "Delete my station",
    },
  },
}

export default data;
