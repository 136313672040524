import React, { useState } from "react";
import { useSelector } from "react-redux";
import close from "../../assets/close.png";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { ThreeDots } from "react-loader-spinner";
import swal from "sweetalert";
import "../../styles/lpo.scss";
import "../../styles/lpo.scss";
import Radio from "@mui/material/Radio";
import "react-html5-camera-photo/build/css/index.css";
import { MenuItem, Select } from "@mui/material";
import { useEffect } from "react";
import ReactCamera from "./ReactCamera";
import UploadPhoto from "../common/uploadphoto";
import APIs from "../../services/connections/api";

const EditAdminModal = (props) => {
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const singleEmployee = useSelector((state) => state.employee.singleEmployee);

  const [cam, setCam] = useState("null");
  const [gall, setGall] = useState("null");
  const [open, setOpen] = useState("");

  const [staffName, setStaffName] = useState("");
  const [sex, setSex] = useState("Male");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [dateEmployed, setDateEmployed] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [role, setRole] = useState(["Admin", "Accountant", "Staff"]);
  const [roleData, setRoleData] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [roleState, setRoleState] = useState(0);
  const [loader, setLoader] = useState(false);
  const [salary, setSalary] = useState("");

  const handleClose = () => {
    props.close(false);
  };

  const resolveUserID = () => {
    if (user.userType === "superAdmin") {
      return { id: user._id };
    } else {
      return { id: user.organisationID };
    }
  };

  useEffect(() => {
    const extensions = [...new Set(props.roles.map((data) => data.role))];
    const existingRoles = [...role].concat(extensions);
    existingRoles.push("Others");
    setRole(existingRoles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setStaffName(singleEmployee.staffName);
    setSex(singleEmployee.sex);
    setEmail(singleEmployee.email);
    setPhone(singleEmployee.phone);
    setAddress(singleEmployee.address);
    setState(singleEmployee.state);
    setAccountNumber(singleEmployee.accountNumber);
    setBankName(singleEmployee.bankName);
    setDateEmployed(singleEmployee.dateEmployed);
    setDateOfBirth(singleEmployee.dateOfBirth);
    setJobTitle(singleEmployee.jobTitle);
    setSalary(singleEmployee.salary);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submit = () => {
    if (staffName === "")
      return swal("Warning!", "Staff name field cannot be empty", "info");
    if (sex === "")
      return swal("Warning!", "Sex field cannot be empty", "info");
    if (email === "")
      return swal("Warning!", "Email field cannot be empty", "info");
    if (phone === "")
      return swal("Warning!", "Phone field cannot be empty", "info");
    if (address === "")
      return swal("Warning!", "Address field cannot be empty", "info");
    if (state === "")
      return swal("Warning!", "State field cannot be empty", "info");
    if (accountNumber === "")
      return swal("Warning!", "Account No field cannot be empty", "info");
    if (bankName === "")
      return swal("Warning!", "Bank name field cannot be empty", "info");
    if (salary === "")
      return swal("Warning!", "Salary field cannot be empty", "info");
    if (dateEmployed === "")
      return swal("Warning!", "Date employed field cannot be empty", "info");
    if (dateOfBirth === "")
      return swal("Warning!", "Date of birth field cannot be empty", "info");
    if (roleData === "")
      return swal("Warning!", "Role field cannot be empty", "info");
    if (jobTitle === "")
      return swal("Warning!", "Job title field cannot be empty", "info");
    if (cam === "null" && gall === "null")
      return swal("Warning!", "File upload cannot be empty", "info");

    setLoading(true);
    setLoader(true);
    const payload = {
      staffName: staffName,
      sex: sex,
      email: email,
      phone: phone,
      address: address,
      state: state,
      accountNumber: accountNumber,
      bankName: bankName,
      salary: salary,
      image: cam === "null" ? gall : cam,
      dateEmployed: dateEmployed,
      dateOfBirth: dateOfBirth,
      role: roleData,
      timezone: user.timezone,
      alias: "Admin Office",
      jobTitle: jobTitle,
      organisationID: resolveUserID().id,
      outletID: "Admin Office",
    };

    const details = {
      id: singleEmployee._id,
      data: payload,
    };

    APIs.post("/hr/employee/update-admin", details)
      .then(({ data }) => {
        swal("Success!", "A new user created successfully!", "success");
      })
      .then(() => {
        setLoading(false);
        setLoader(false);
        props.refresh();

        setStaffName("");
        setSex("");
        setEmail("");
        setPhone("");
        setAddress("");
        setState("");
        setAccountNumber("");
        setBankName("");
        setSalary("");
        setDateEmployed("");
        setDateOfBirth("");
        setRoleState(0);
        setRoleData("");
        setJobTitle("");
        handleClose();
      });
  };

  const changeRoleMenu = (index, data) => {
    setRoleState(index);

    if (roleState === props.roleList.length) {
      setRoleData("");
    } else {
      setRoleData(data);
    }
  };

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <div className="modalContainer2">
        <ReactCamera open={open} close={setOpen} setDataUri={setCam} />
        <div className="inner">
          <div className="head">
            <div className="head-text">Edit Admin Staff</div>
            <img
              onClick={handleClose}
              style={{ width: "18px", height: "18px" }}
              src={close}
              alt={"icon"}
            />
          </div>

          <div className="middleDiv" style={inner}>
            <div className="inputs">
              <div className="head-text2">Staff Name</div>
              <input
                style={{
                  width: "94%",
                  outline: "none",
                  paddingLeft: "10px",
                  height: "35px",
                  marginTop: "5px",
                  background: "#EEF2F1",
                  fontSize: "12px",
                  borderRadius: "0px",
                  border: "1px solid #777777",
                }}
                value={staffName}
                placeholder=""
                type="text"
                onChange={(e) => setStaffName(e.target.value)}
              />
            </div>
            <div className="inputs">
              <div className="head-text2">Sex</div>
              <div className="radio">
                <div className="rad-item">
                  <Radio
                    onClick={() => {
                      setSex("Male");
                    }}
                    checked={sex === "Male" ? true : false}
                  />
                  <div className="head-text2" style={{ marginRight: "5px" }}>
                    Male
                  </div>
                </div>
                <div className="rad-item">
                  <Radio
                    onClick={() => {
                      setSex("Female");
                    }}
                    checked={sex === "Female" ? true : false}
                  />
                  <div className="head-text2" style={{ marginRight: "5px" }}>
                    Female
                  </div>
                </div>
              </div>
            </div>
            <div className="inputs">
              <div className="head-text2">Email</div>
              <input
                style={{
                  width: "94%",
                  outline: "none",
                  paddingLeft: "10px",
                  height: "35px",
                  marginTop: "5px",
                  background: "#EEF2F1",
                  fontSize: "12px",
                  borderRadius: "0px",
                  border: "1px solid #777777",
                }}
                value={email}
                placeholder=""
                type="text"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="inputs">
              <div className="head-text2">Phone Number</div>
              <input
                style={{
                  width: "94%",
                  outline: "none",
                  paddingLeft: "10px",
                  height: "35px",
                  marginTop: "5px",
                  background: "#EEF2F1",
                  fontSize: "12px",
                  borderRadius: "0px",
                  border: "1px solid #777777",
                }}
                value={phone}
                type="number"
                placeholder=""
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className="inputs">
              <div className="head-text2">Home Address</div>
              <input
                style={{
                  width: "94%",
                  outline: "none",
                  paddingLeft: "10px",
                  height: "35px",
                  marginTop: "5px",
                  background: "#EEF2F1",
                  fontSize: "12px",
                  borderRadius: "0px",
                  border: "1px solid #777777",
                }}
                value={address}
                placeholder=""
                type="text"
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className="inputs">
              <div className="head-text2">State Of Origin</div>
              <input
                style={{
                  width: "94%",
                  outline: "none",
                  paddingLeft: "10px",
                  height: "35px",
                  marginTop: "5px",
                  background: "#EEF2F1",
                  fontSize: "12px",
                  borderRadius: "0px",
                  border: "1px solid #777777",
                }}
                value={state}
                placeholder=""
                type="text"
                onChange={(e) => setState(e.target.value)}
              />
            </div>
            <div className="inputs">
              <div className="head-text2">Account Number</div>
              <input
                style={{
                  width: "94%",
                  outline: "none",
                  paddingLeft: "10px",
                  height: "35px",
                  marginTop: "5px",
                  background: "#EEF2F1",
                  fontSize: "12px",
                  borderRadius: "0px",
                  border: "1px solid #777777",
                }}
                value={accountNumber}
                placeholder=""
                type="text"
                onChange={(e) => setAccountNumber(e.target.value)}
              />
            </div>
            <div className="inputs">
              <div className="head-text2">Bank Name</div>
              <input
                style={{
                  width: "94%",
                  outline: "none",
                  paddingLeft: "10px",
                  height: "35px",
                  marginTop: "5px",
                  background: "#EEF2F1",
                  fontSize: "12px",
                  borderRadius: "0px",
                  border: "1px solid #777777",
                }}
                value={bankName}
                placeholder=""
                type="text"
                onChange={(e) => setBankName(e.target.value)}
              />
            </div>
            <div className="inputs">
              <div className="head-text2">Salary</div>
              <input
                style={{
                  width: "94%",
                  outline: "none",
                  paddingLeft: "10px",
                  height: "35px",
                  marginTop: "5px",
                  background: "#EEF2F1",
                  fontSize: "12px",
                  borderRadius: "0px",
                  border: "1px solid #777777",
                }}
                value={salary}
                placeholder=""
                type="text"
                onChange={(e) => setSalary(e.target.value)}
              />
            </div>
            <div className="inputs">
              <div className="head-text2">Date Employed</div>
              <input
                style={{
                  width: "94%",
                  outline: "none",
                  paddingLeft: "10px",
                  height: "35px",
                  marginTop: "5px",
                  background: "#EEF2F1",
                  fontSize: "12px",
                  borderRadius: "0px",
                  border: "1px solid #777777",
                  userSelect: "none",
                }}
                value={dateEmployed}
                placeholder=""
                type="date"
                onChange={(e) => setDateEmployed(e.target.value)}
              />
            </div>
            <div className="inputs">
              <div className="head-text2">Date Of Birth</div>
              <input
                style={{
                  width: "94%",
                  outline: "none",
                  paddingLeft: "10px",
                  height: "35px",
                  marginTop: "5px",
                  background: "#EEF2F1",
                  fontSize: "12px",
                  borderRadius: "0px",
                  border: "1px solid #777777",
                  userSelect: "none",
                }}
                value={dateOfBirth}
                placeholder=""
                type="date"
                onChange={(e) => setDateOfBirth(e.target.value)}
              />
            </div>
            <div style={{ marginTop: "15px" }} className="inputs">
              <div className="head-text2">Role</div>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={roleState}
                sx={{
                  width: "98%",
                  outline: "none",
                  height: "35px",
                  marginTop: "5px",
                  background: "#EEF2F1",
                  fontSize: "12px",
                  borderRadius: "0px",
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #777777",
                  },
                }}>
                <MenuItem style={menu} value={0}>
                  Select a designation
                </MenuItem>
                {role.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        changeRoleMenu(index + 1, item);
                      }}
                      style={menu}
                      value={index + 1}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            {roleState === props.roleList.length && (
              <div className="inputs">
                <div className="head-text2">Specify for others</div>
                <input
                  style={{
                    width: "94%",
                    outline: "none",
                    paddingLeft: "10px",
                    height: "35px",
                    marginTop: "5px",
                    background: "#EEF2F1",
                    fontSize: "12px",
                    borderRadius: "0px",
                    border: "1px solid #777777",
                  }}
                  placeholder=""
                  type="text"
                  onChange={(e) => setRoleData(e.target.value)}
                />
              </div>
            )}
            <div className="inputs">
              <div className="head-text2">Job Title</div>
              <input
                style={{
                  width: "94%",
                  outline: "none",
                  paddingLeft: "10px",
                  height: "35px",
                  marginTop: "5px",
                  background: "#EEF2F1",
                  fontSize: "12px",
                  borderRadius: "0px",
                  border: "1px solid #777777",
                }}
                value={jobTitle}
                placeholder=""
                type="text"
                onChange={(e) => setJobTitle(e.target.value)}
              />
            </div>
            <UploadPhoto setOpen={setOpen} setGall={setGall} cam={cam} />
          </div>

          <div style={{ marginTop: "10px", height: "30px" }} className="butt">
            <Button
              disabled={loader}
              sx={{
                width: "100px",
                height: "30px",
                background: "#427BBE",
                borderRadius: "3px",
                fontSize: "10px",
                marginTop: "0px",
                "&:hover": {
                  backgroundColor: "#427BBE",
                },
              }}
              onClick={submit}
              variant="contained">
              {" "}
              Add User
            </Button>

            {loading ? (
              <ThreeDots
                height="60"
                width="50"
                radius="9"
                color="#076146"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            ) : null}
          </div>
        </div>
      </div>
    </Modal>
  );
};

const inner = {
  width: "100%",
  height: "500px",
  overflowY: "scroll",
};

const menu = {
  fontSize: "12px",
};

export default EditAdminModal;
