import React from "react";
import ThreeDotsLoader from "../../common/ThreeDotsLoader";
import "../../../styles/common/table.scss";
import empty from "../../../assets/comp/No data-cuate 1.svg";

export const TableViewForDesktop = ({ children, columns, ref }) => {
  return (
    <div ref={ref} className="table-container">
      <div className="table-head">
        {columns.map((item, index) => {
          return (
            <div key={index} className="column">
              {item}
            </div>
          );
        })}
      </div>
      {children}
    </div>
  );
};

export const DesktopTableRowContainer = ({ children, rows, loading }) => {
  return (
    <React.Fragment>
      {loading && <ThreeDotsLoader />}
      {rows.length === 0 && !loading && <div style={place}>
        <img style={emptyStyle} src={empty} alt="No data" />
        <div>No records for the current filter</div>
      </div>}
      {children}
    </React.Fragment>
  );
};

export const DesktopTableRows = ({ children, index, callback }) => {
  return (
    <React.Fragment>
      <div key={index} onClick={callback} className="row-container">
        <div className="table-head2">{children}</div>
      </div>
    </React.Fragment>
  );
};

export const DesktopTableCell = ({ children, data }) => {
  return (
    <div className="column">
      <React.Fragment>
        {children}
        {data}
      </React.Fragment>
    </div>
  );
};

const place = {
  width: "100%",
  textAlign: "center",
  fontSize: "12px",
  marginTop: "20px",
  color: "green",
};

const emptyStyle = {
  width: "200px",
  height: "200px"
}
