export const routeNames = {
  "/home/dashboard/dashboardhome/0": "Dashboard",
  "/home/dashboard/dashboardemployee": "Employee List",
  "/home/dashboard/dashboardoverage": "Overage Details",
  "/home/dashboard/stationtanks": "Tank Assets",
  "/home/dashboard/stationpumps": "Pump Assets",
  "/home/dashboard/saleslist": "Sales List",
  "/home/dailysales/dailysaleshome/0": "Daily Sales",
  "/home/dailysales/pmssales": "PMS Sales",
  "/home/dailysales/agosales": "AGO Sales",
  "/home/dailysales/dpksales": "DPK Sales",
  "/home/dailysales/comprehensive": "Comprehensive Report",
  "/home/dailysales/tanklist": "Tank Updates",
  "/home/dailysales/dailysalesoverage": "Overage Details",
  "/home/dailysales/outstanding": "Outstanding Payments",
  "/home/mystation/mystationhome/0": "My Station",
  "/home/mystation/sales": "Station Sales",
  "/home/mystation/tanks": "Station Tanks",
  "/home/mystation/pumps": "Station Pumps",
  "/home/recordsales/pumpupdate/0": "Pump Updates",
  "/home/recordsales/rttank": "Return To Tank",
  "/home/recordsales/lpo": "LPO Sales",
  "/home/recordsales/expenses": "Record Expenses",
  "/home/recordsales/payments": "Record Payments",
  "/home/recordsales/dipping": "Record Dipping",
  "/home/analysis/analysishome/0": "Analysis",
  "/home/analysis/payments": "Payments",
  "/home/analysis/expenses": "Expenses",
  "/home/lposales/lposaleshome/0": "Corporate Sales",
  "/home/lposales/corporatecustomer": "Customer Account",
  "/home/lposales/transactions": "Transactions",
  "/home/lposales/lpoexpense": "Expense Details",
  "/home/lposales/dispensedproducts": "Dispensed Products",
  "/home/productorder/productorderhome/0": "Product Order",
  "/home/productorder/deliveredorder": "Delivered Order",
  "/home/unallocatedorder/unallocatedorderhome/0": "Incoming Order",
  "/home/unallocatedorder/incomingorder": "Allocated Order",
  "/home/supply/supplyhome/0": "Supply",
  "/home/supply/createsupply": "Create Supply",
  "/home/regulatory": "Regulatory",
  "/home/tankupdate": "Tank Update",
  "/home/hr/hrhome/0": "Human Resources",
  "/home/hr/hremployees": "Employee Details",
  "/home/hr/salary": "Salary",
  "/home/hr/query": "Query",
  "/home/hr/attendance": "Attendance",
  "/home/hr/shifts": "Employee Shifts",
  "/home/hr/admin": "Administrative Office",
  "/home/settings": "Settings",
};
