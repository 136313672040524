const data = [
  {
    id: `${Math.random()}`,
    amount: "122,293.00 ",
    pms: "01",
    opening: "122,293 ",
    difference: "122,293 ",
    lop: "283,922 ",
    rate: "283,922",
    total: "283,922",
    closing: "122,293 ",
  },
  {
    id: `${Math.random()}`,
    amount: "122,293.00 ",
    pms: "02",
    opening: "122,293 ",
    difference: "122,293 ",
    lop: "283,922 ",
    rate: "283,922",
    closing: "122,293 ",
  },
  {
    id: `${Math.random()}`,
    amount: "122,293.00 ",
    pms: "03",
    opening: "122,293 ",
    closing: "122,293 ",
    difference: "122,293 ",
    lop: "283,922 ",
    rate: "283,922",
  },
  {
    id: `${Math.random()}`,
    amount: "122,293.00 ",
    pms: "04",
    opening: "122,293 ",
    difference: "122,293 ",
    lop: "283,922 ",
    rate: "283,922",
    closing: "122,293 ",
  },
  {
    id: `${Math.random()}`,
    amount: "122,293.00 ",
    pms: "05",
    opening: "122,293 ",
    difference: "122,293 ",
    lop: "283,922 ",
    rate: "283,922",
    closing: "122,293 ",
  },
];
const dataT2 = [
  {
    id: `${Math.random()}`,
    pms: "01",
    opening: "122,293 ",
    closing: "832,029",
    difference: "122,293 ",
    lpo: "122,293 ",
    rate: "122.00 ",
    amount: "122,293.00 ",
  },
  {
    id: `${Math.random()}`,
    pms: "01",
    opening: "122,293 ",
    closing: "832,029",
    difference: "122,293 ",
    lpo: "122,293 ",
    rate: "122.00 ",
    amount: "122,293.00 ",
  },
  {
    id: `${Math.random()}`,
    pms: "01",
    opening: "122,293 ",
    closing: "832,029",
    difference: "122,293 ",
    lpo: "122,293 ",
    rate: "122.00 ",
    amount: "122,293.00 ",
  },
  {
    id: `${Math.random()}`,
    pms: "01",
    opening: "122,293 ",
    closing: "832,029",
    difference: "122,293 ",
    lpo: "122,293 ",
    rate: "122.00 ",
    amount: "122,293.00 ",
  },
  {
    id: `${Math.random()}`,
    pms: "01",
    opening: "122,293 ",
    closing: "832,029",
    difference: "122,293 ",
    lpo: "122,293 ",
    rate: "122.00 ",
    amount: "122,293.00 ",
  },
];
const data2 = [
  {
    id: `${Math.random()}`,
    amount: "122,293.00 ",
    pms: "01",
    opening: "122,293 ",
    difference: "122,293 ",
    lop: "283,922 ",
    rate: "283,922",
    total: "283,922",
    closing: "122,293 ",
  },
  {
    id: `${Math.random()}`,
    amount: "122,293.00 ",
    pms: "02",
    opening: "122,293 ",
    difference: "122,293 ",
    lop: "283,922 ",
    rate: "283,922",
    closing: "122,293 ",
  },
  {
    id: `${Math.random()}`,
    amount: "122,293.00 ",
    pms: "03",
    opening: "122,293 ",
    closing: "122,293 ",
    difference: "122,293 ",
    lop: "283,922 ",
    rate: "283,922",
  },
];
const footer = (data) => [
  {
    id: `${Math.random()}`,
    value: "",
  },
  {
    id: `${Math.random()}`,
    value: " ",
  },
  {
    id: `${Math.random()}`,
    value: "Total",
  },
  {
    id: `${Math.random()}`,
    value: `${data?.difference}`,
  },
  {
    id: `${Math.random()}`,
    value: "",
  },

  {
    id: `${Math.random()}`,
    value: `${data?.total}`,
  },
];
const header = [
  {
    id: `${Math.random()}`,
    value: "PMS",
  },
  {
    id: `${Math.random()}`,
    value: "Opening",
  },
  {
    id: `${Math.random()}`,
    value: "Closing",
  },
  {
    id: `${Math.random()}`,
    value: "Difference",
  },
  // {
  //   id: `${Math.random()}`,
  //   value: "LP0",
  // },
  {
    id: `${Math.random()}`,
    value: "Rate",
  },
  {
    id: `${Math.random()}`,
    value: "Amount",
  },
];
const header2 = [
  {
    id: `${Math.random()}`,
    value: "AGO",
  },
  {
    id: `${Math.random()}`,
    value: "Opening",
  },
  {
    id: `${Math.random()}`,
    value: "Closing",
  },
  {
    id: `${Math.random()}`,
    value: "Difference",
  },
  {
    id: `${Math.random()}`,
    value: "LP0",
  },
  {
    id: `${Math.random()}`,
    value: "Rate",
  },
  {
    id: `${Math.random()}`,
    value: "Amount",
  },
];
const header3 = [
  {
    id: `${Math.random()}`,
    value: "DPK",
  },
  {
    id: `${Math.random()}`,
    value: "Opening",
  },
  {
    id: `${Math.random()}`,
    value: "Closing",
  },
  {
    id: `${Math.random()}`,
    value: "Difference",
  },
  {
    id: `${Math.random()}`,
    value: "LP0",
  },
  {
    id: `${Math.random()}`,
    value: "Rate",
  },
  {
    id: `${Math.random()}`,
    value: "Amount",
  },
];
const lpoHeader = [
  {
    id: `${Math.random()}`,
    value: "S/N",
  },
  {
    id: `${Math.random()}`,
    value: "Account Name",
  },
  {
    id: `${Math.random()}`,
    value: "Product",
  },
  {
    id: `${Math.random()}`,
    value: "Truck No",
  },
  {
    id: `${Math.random()}`,
    value: "LTR",
  },
  {
    id: `${Math.random()}`,
    value: "Rate",
  },
  {
    id: `${Math.random()}`,
    value: "Amount",
  },
];
const lpoData = [
  {
    id: `${Math.random()}`,
    amount: "01 ",
    pms: "Novare Center ",
    opening: "PMS ",
    difference: "132KSA-ABJ",
    lop: "122,293  ",
    rate: "122.00 ",
    closing: "122,293.00 ",
  },
  {
    id: `${Math.random()}`,
    amount: "01 ",
    pms: "Novare Center ",
    opening: "PMS ",
    difference: "132KSA-ABJ",
    lop: "122,293  ",
    rate: "122.00 ",
    closing: "122,293.00 ",
  },
  {
    id: `${Math.random()}`,
    amount: "01 ",
    pms: "Novare Center ",
    opening: "PMS ",
    difference: "132KSA-ABJ",
    lop: "122,293  ",
    rate: "122.00 ",
    closing: "122,293.00 ",
  },
  {
    id: `${Math.random()}`,
    amount: "01 ",
    pms: "Novare Center ",
    opening: "PMS ",
    difference: "132KSA-ABJ",
    lop: "122,293  ",
    rate: "122.00 ",
    closing: "122,293.00 ",
  },
];

const lpoFooter = [
  {
    id: `${Math.random()}`,
    value: "",
  },
  {
    id: `${Math.random()}`,
    value: " ",
  },
  {
    id: `${Math.random()}`,
    value: "",
  },
  {
    id: `${Math.random()}`,
    value: " ",
  },
  {
    id: `${Math.random()}`,
    value: "",
  },
  {
    id: `${Math.random()}`,
    value: "Total",
  },
  {
    id: `${Math.random()}`,
    value: "283,922.00 ",
  },
];
const footerT6 = [
  {
    id: `${Math.random()}`,
    value: "",
  },
  {
    id: `${Math.random()}`,
    value: "Total",
  },
  {
    id: `${Math.random()}`,
    value: "283,922.00 ",
  },
];
const dataT6 = [
  {
    id: `${Math.random()}`,
    sn: "01 ",
    exp: "Novare Center ",
    amount: "122,293.00 ",
  },
  {
    id: `${Math.random()}`,
    sn: "02 ",
    exp: "Novare Center ",
    amount: "122,293.00 ",
  },
  {
    id: `${Math.random()}`,
    sn: "03 ",
    exp: "Novare Center ",
    amount: "122,293.00 ",
  },
  {
    id: `${Math.random()}`,
    sn: "04 ",
    exp: "Novare Center ",
    amount: "122,293.00 ",
  },
];
const dataT8 = [
  {
    id: `${Math.random()}`,
    sn: "PMS ",
    exp: "122,293.00  ",
    amount: "Oloyode Haruna Ishola ",
  },
  {
    id: `${Math.random()}`,
    sn: "AGO ",
    exp: "122,293.00  ",
    amount: "Oloyode Haruna Ishola ",
  },
  {
    id: `${Math.random()}`,
    sn: "GPK ",
    exp: "122,293.00  ",
    amount: "Oloyode Haruna Ishola ",
  },
];
const dataT9 = [
  {
    id: `${Math.random()}`,
    tank: 1,
    pms: "122,293.00  ",
    ago: "122,293.00  ",
    dpk: "122,293.00 ",
  },
  {
    id: `${Math.random()}`,
    tank: 1,
    pms: "122,293.00  ",
    ago: "122,293.00  ",
    dpk: "122,293.00 ",
  },
  {
    id: `${Math.random()}`,
    tank: 1,
    pms: "122,293.00  ",
    ago: "122,293.00  ",
    dpk: "122,293.00 ",
  },
  {
    id: `${Math.random()}`,
    tank: 1,
    pms: "122,293.00  ",
    ago: "122,293.00  ",
    dpk: "122,293.00 ",
  },
];
const headerT6 = [
  {
    id: `${Math.random()}`,
    value: "S/N",
  },
  {
    id: `${Math.random()}`,
    value: "Expenses Name",
  },
  {
    id: `${Math.random()}`,
    value: "Amount",
  },
];
const headerT8 = [
  {
    id: `${Math.random()}`,
    value: "S/N",
  },
  {
    id: `${Math.random()}`,
    value: "Product Type",
  },
  {
    id: `${Math.random()}`,
    value: "Quantity",
  },
];

const headerT9 = [
  {
    id: `${Math.random()}`,
    value: "S/N",
  },
  {
    id: `${Math.random()}`,
    value: "Tank Name",
  },
  {
    id: `${Math.random()}`,
    value: "Product Name",
  },
  {
    id: `${Math.random()}`,
    value: "Computed Level",
  },
  {
    id: `${Math.random()}`,
    value: "Dipping",
  },
  {
    id: `${Math.random()}`,
    value: "Difference",
  },
];

export {
  footerT6,
  headerT6,
  data,
  lpoData,
  lpoHeader,
  lpoFooter,
  data2,
  header,
  dataT2,
  header2,
  dataT6,
  dataT8,
  headerT9,
  header3,
  footer,
  headerT8,
  dataT9,
};
