import { clearAnalysis } from "./analysis";
import { clearAttendance } from "./attendance";
import { clearAuth, goToLogin } from "./auth";
import { clearComprehensive } from "./comprehensive";
import { clearSales } from "./dailysales";
import { clearDashboard } from "./dashboard";
import { clearEmployees } from "./employee";
import { clearExpenses } from "./expenses";
import { clearIncomingOrder } from "./incomingOrder";
import { clearLpo } from "./lpo";
import { clearOutlet } from "./outlet";
import { clearPayment } from "./payment";
import { clearProductOrder } from "./productOrder";
import { clearQuery } from "./query";
import { clearRecordSales } from "./recordsales";
import { clearRegulatory } from "./regulatory";
import { clearSalary } from "./salary";
import { clearSetting } from "./settings";
import { clearSupply } from "./supply";
import { store } from "./store";

export const logout = () => {
  store.dispatch(clearAnalysis());
  store.dispatch(clearAttendance());
  store.dispatch(clearAuth());
  store.dispatch(clearComprehensive());
  store.dispatch(clearSales());
  store.dispatch(clearDashboard());
  store.dispatch(clearEmployees());
  store.dispatch(clearExpenses());
  store.dispatch(clearIncomingOrder());
  store.dispatch(clearLpo());
  store.dispatch(clearOutlet());
  store.dispatch(clearPayment());
  store.dispatch(clearProductOrder());
  store.dispatch(clearQuery());
  store.dispatch(clearRecordSales());
  store.dispatch(clearRegulatory());
  store.dispatch(clearSalary());
  store.dispatch(clearSetting());
  store.dispatch(clearSupply());
  store.dispatch(goToLogin(false));
  localStorage.clear();
};
