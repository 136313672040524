const Pitch = () => {
  return (
    <div className="pitch">
      <div className="inner">
        <span className="dig">Why you should digitize your oulet</span>
        <div className="reason">Reasons to Use...</div>
        <div className="test">
          <div className="inn">
            <div className="num">O1</div>
            <div className="txt">
              <span className="hd">Gain Better Control</span>
              <div className="bd">
                With 360 Station, you can streamline your purchasing process.
                Our system provides a centralized platform for managing supply
                orders. By digitizing your purchasing, you can automate routine
                tasks. This streamlines your procurement process, improves
                workflow, reduces errors, and ensures the timely delivery of
                products and supplies.
              </div>
            </div>
          </div>

          <div className="inn">
            <div className="num">O2</div>
            <div className="txt">
              <span className="hd">Gain Better Insights</span>
              <div className="bd">
                Unlock the full potential of digital tools to streamline and
                optimize your outlet’s operations. 360 Station empowers you to
                leverage automation, analytics, and integrated systems for
                improved productivity and profitability. You can seamlessly
                integrate the software with other business systems, including
                fuel dispensers, ensures smooth data flow, eliminating manual
                data entry. By utilizing the power of digital management, you
                can streamline processes, reduce costs, and make informed
                decisions based on accurate insights.
              </div>
            </div>
          </div>

          <div className="inn">
            <div className="num">O3</div>
            <div className="txt">
              <span className="hd">Managing Fuel Outlets Effectively</span>
              <div className="bd">
                Digitizing your outlet enables seamless management of all
                fueling activities, from procurement to sales tracking.
                Experience greater control, accuracy, and visibility over your
                daily operations. With 360 Station, you can optimize fuel
                procurement by analyzing supplier performance, tracking product
                orders, incoming orders, daily sales, expenses, payments, and
                regulatory payments. By digitizing your activities, you gain
                control over every aspect of your fueling outlet, ensuring
                smooth operations, and ensuring transparency.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pitch;
