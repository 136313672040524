import ft1 from "../../../assets/landing/features/ft1.svg";
import ft2 from "../../../assets/landing/features/ft2.svg";
import ft3 from "../../../assets/landing/features/ft3.svg";
import ft4 from "../../../assets/landing/features/ft4.svg";
import ft5 from "../../../assets/landing/features/ft5.svg";
import ft6 from "../../../assets/landing/features/ft6.svg";
import ft7 from "../../../assets/landing/features/ft7.svg";
import ft8 from "../../../assets/landing/features/ft8.svg";
import ft9 from "../../../assets/landing/features/ft9.svg";
import ft10 from "../../../assets/landing/features/ft10.svg";
import ft11 from "../../../assets/landing/features/ft11.svg";
import ft12 from "../../../assets/landing/features/ft12.svg";
import ft13 from "../../../assets/landing/features/ft13.svg";
import ft14 from "../../../assets/landing/features/ft14.svg";
import ft15 from "../../../assets/landing/features/ft15.svg";
import ft16 from "../../../assets/landing/features/ft16.svg";

const FeatureData = [
  {
    title: "Bulk Fuel Orders Tracking",
    image: ft1,
    content:
      "With 360 Station, filling station owners can effortlessly place and track bulk fuel orders they make from oil depots. Our system keeps record of procurement process, allowing you to order millions of liters of fuel with ease. By streamlining the order management workflow, you can be able to keep track of how much product you have ordered and its price, how much is loaded & which truck loads the products, with their destinations of discharge as well as the balance that has not been loaded.",
  },
  {
    title: "Daily Loading and Destination Tracking",
    image: ft2,
    content:
      "We understand the importance of efficient logistics management in the oil and gas industry. Our software enables you to monitor the daily loading of your supply onto trucks and track the destinations of each loaded product. By having real-time visibility into the movement of your product, you can optimize delivery routes, minimize transportation costs, and ensure accurate distribution to your various fueling stations or customers.",
  },
  {
    title: "Product Order Balances",
    image: ft3,
    content:
      "Managing inventory effectively is crucial for businesses in the oil and gas industry. With 360 Station, you can monitor the daily balances of your product orders after loading. This feature provides you with a clear picture of the remaining quantities, allowing you to plan your inventory product orders and incoming orders efficiently. By tracking your product order balances, you can accurately manage your supply, reduce costs, and ensure uninterrupted product supply to your stations and customers .",
  },
  {
    title: "Oil Truck Supplies",
    image: ft4,
    content:
      "Our software allows you to keep track of the daily supplies of oil trucks to your various filling stations or customers. This feature enables you to monitor the movement of your fuel, ensuring timely delivery and preventing any potential delays or discrepancies. By efficiently managing your distribution, you can enhance customer satisfaction, maintain strong relationships with your clients, and streamline your overall operations.",
  },
  {
    title: "Fuel Sales Record",
    image: ft5,
    content:
      "Accurate sales tracking is essential for filling stations to measure performance and make informed business decisions. With 360 Station, you can maintain comprehensive daily records of sales across all your stations. Our software provides detailed insights into your sales performance, allowing you to identify trends/opportunities, evaluate the success of marketing strategies, and optimize your revenue generation. By understanding your sales patterns, you can make data-driven decisions that will drive the growth of your business.",
  },
  {
    title: "Remittance Tracking",
    image: ft6,
    content:
      "Financial transparency and accountability are crucial for the success of any filling station. Our software enables you to record the amount to be remitted to the company account by each station after sales, along with the actual payment and attached payment proof. This feature ensures that all financial transactions are accurately documented, reducing the risk of funds diversion and ensuring the integrity of your financial processes. By maintaining transparent financial records, you can foster trust with stakeholders and enhance your reputation in the industry.",
  },
  {
    title: "Expenditure Recording",
    image: ft7,
    content:
      "Keeping a record of daily expenditures incurred in your filling stations is essential for effective financial management. With 360 Station, you can easily record and track your daily expenses in each station. This feature allows you to monitor your expenditure, identify cost-saving opportunities, and ensure that your financial resources are utilized optimally. By having a clear understanding of your expenses, you can make informed decisions to improve profitability and allocate resources strategically.",
  },
  {
    title: "Corporate Sales Management",
    image: ft8,
    content:
      "Filling stations often cater to corporate customers with unique requirements and credit arrangements. With 360 Station, you can efficiently manage corporate sales by maintaining a detailed account record of balances, funds payments, expenditures, and credit limits for each corporate customer. This feature simplifies the management of corporate accounts, ensuring timely payments, minimizing payment disputes, and fostering strong business relationships. By providing exceptional service to your corporate clients, you can secure long-term partnerships and increase your revenue streams.",
  },
  {
    title: "Fuel Storage Tank",
    image: ft9,
    content:
      "Our software allows you to maintain a comprehensive record of the number of product storage tanks in each of your filling stations, along with their respective consumption capacities. This feature provides you with an accurate overview of your supply inventory and storage capabilities. By effectively managing your storage, you can optimize your inventory levels, prevent shortages, and avoid unnecessary losses associated with shortages. Our system ensures that you have the right amount of supply (PMS, AGO, DPK) at the right time, maximizing operational efficiency and customer satisfaction.",
  },
  {
    title: "Fuel Stock Levels",
    image: ft10,
    content:
      "Monitoring fuel stock levels in real-time is vital for efficient filling station management. With 360 Station, you can track the daily supply levels before and after sales at each station. This feature provides you with up-to-date information about your whole inventory, allowing you to plan for incoming supplies effectively. By avoiding shortages and ensuring adequate product supply, you can meet customer demands consistently and maintain a competitive edge in the market.",
  },
  {
    title: "Fuel Dispensers Management",
    image: ft11,
    content:
      "Accurate monitoring of fuel dispensers is essential for tracking sales, preventing mismanagement of funds, and maintaining operational efficiency. Our software allows you to keep track of all the fuel dispensers (pumps) in each of your stations. This feature provides you with real-time visibility into fuel dispensing activities, ensuring accurate sales recording and minimizing the risk of errors or discrepancies. By effectively managing your fuel dispensers, you can optimize fuel usage, enhance customer service, and maximize revenue generation.",
  },
  {
    title: "Regulatory Payment Record",
    image: ft12,
    content:
      "Compliance with regulatory requirements is a priority for every filling station. With 360 Station, you can maintain a detailed record of regulatory payments made to government agencies and store documentation for future reference. This feature ensures that you adhere to all regulatory obligations, simplifies audits and inspections, and mitigate the risk of non-compliance. By maintaining accurate and accessible payment records, you can demonstrate your commitment to regulatory compliance and avoid legal complications.",
  },
  {
    title: "Payment Discrepancy Management",
    image: ft13,
    content:
      "Keeping a detailed record of shortages or overages in payment by each station is crucial for financial transparency and accountability. Our software enables you to track and manage payment discrepancies effectively. This feature helps you identify any payment irregularities, reconcile accounts accurately, and maintain financial accuracy across your filling stations.",
  },
  {
    title: "Daily Profitability Analysis",
    image: ft14,
    content:
      "Understanding the profitability of each station is vital for making informed business decisions and optimizing performance. With 360 Station, you can generate daily profitability analysis for each station. This feature provides you with valuable insights into the financial performance of each location, allowing you to identify strengths, weaknesses, and opportunities. By analyzing profitability metrics, you can fine-tune your operations, implement effective cost-saving strategies, and maximize overall profitability.",
  },
  {
    title: "Employee Management",
    image: ft15,
    content:
      "Managing your staff effectively is key to maintaining a productive and motivated workforce. With 360 Station, you can maintain a comprehensive record with documentation of all staff members in your various stations, including their salaries. This feature simplifies human resource management, ensuring accurate payroll processing, tracking employee performance, and fostering a positive work environment.",
  },
  {
    title: "Performance Reports",
    image: ft16,
    content:
      "Our software enables you to generate daily, weekly, monthly, and yearly performance reports for each station. These reports provide a detailed analysis of your fueling stations' financial performance, including revenue, expenses, profitability, and other key metrics. This feature empowers you to evaluate the success of your business strategies, identify areas for improvement, and make data-driven decisions. By having comprehensive performance reports at your fingertips, you can optimize your operations, drive growth, and stay ahead in the industry.",
  },
];

export default FeatureData;
