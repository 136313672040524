import React from "react";
import close from "../../assets/close.png";
import pumpHead from "../../assets/pumpHead.png";
import Modal from "@mui/material/Modal";
import "../../styles/cost.scss";
import { useDispatch, useSelector } from "react-redux";
import ApproximateDecimal from "../common/approx";
import { overageType } from "../../storage/dashboard";
import { useNavigate } from "react-router-dom";

const Varience = (props) => {
  const { varience } = useSelector((state) => state.analysis.analysisData);
  const { quantity, price } = varience;

  const handleClose = () => props.close(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const changePage = (data) => {
    if (data === "pms") {
      dispatch(overageType("PMS"));
      navigate("/home/dashboard/dashboardoverage");
    } else if (data === "ago") {
      dispatch(overageType("AGO"));
      navigate("/home/dashboard/dashboardoverage");
    } else if (data === "dpk") {
      dispatch(overageType("DPK"));
      navigate("/home/dashboard/dashboardoverage");
    }
  };

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <div
        style={{ background: "#fff", padding: "10px" }}
        className="modalContainer">
        <div style={{ height: "85%" }} className="inner">
          <div className="head">
            <div className="head-text"></div>
            <img
              onClick={handleClose}
              style={{ width: "18px", height: "18px" }}
              src={close}
              alt={"icon"}
            />
          </div>
          <div className="cont">
            <div className="card">
              <div onClick={() => changePage("pms")} className="inCard">
                <div className="left">
                  <img
                    src={pumpHead}
                    style={{ width: "80px", height: "80px" }}
                    alt="icon"
                  />
                </div>
                <div className="right">
                  <div className="content">
                    <span className="head">PMS</span>
                    <span className="head">
                      {ApproximateDecimal(quantity.pms)} Ltrs
                    </span>
                    <div style={{ marginTop: "10px" }} className="cont">
                      Varience Amount
                    </div>
                    <div className="cont">
                      NGN {ApproximateDecimal(price.pms)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div onClick={() => changePage("ago")} className="inCard">
                <div className="left">
                  <img
                    src={pumpHead}
                    style={{ width: "80px", height: "80px" }}
                    alt="icon"
                  />
                </div>
                <div className="right">
                  <div className="content">
                    <span className="head">AGO</span>
                    <span className="head">
                      {ApproximateDecimal(quantity.ago)} Ltrs
                    </span>
                    <div style={{ marginTop: "10px" }} className="cont">
                      Varience Amount
                    </div>
                    <div className="cont">
                      NGN {ApproximateDecimal(price.ago)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div onClick={() => changePage("dpk")} className="inCard">
                <div className="left">
                  <img
                    src={pumpHead}
                    style={{ width: "80px", height: "80px" }}
                    alt="icon"
                  />
                </div>
                <div className="right">
                  <div className="content">
                    <span className="head">DPK</span>
                    <span className="head">
                      {ApproximateDecimal(quantity.dpk)} Ltrs
                    </span>
                    <div style={{ marginTop: "10px" }} className="cont">
                      Varience Amount
                    </div>
                    <div className="cont">
                      NGN {ApproximateDecimal(price.dpk)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Varience;
