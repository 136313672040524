import mission from "../../../assets/landing/about/mission.svg";
import fuel from "../../../assets/landing/about/fuel.svg";
import financial from "../../../assets/landing/about/financial.svg";
import staff from "../../../assets/landing/about/staff.svg";

const data = [
  {
    title: "Inventory Control & Tracking:",
    image: mission,
    content:
      "Take control of your inventory and put an end to those frustrating discrepancies with our intuitive software solutions. We are well aware of the everyday challenges faced by station owners, and our services are here to make your life easier. We offer features that let you monitor your product supplies, track daily loading activities, and keep a close eye on stock levels accurately.",
  },
  {
    title: "FUEL MANAGEMENT SOLUTIONS",
    image: fuel,
    content:
      "At 360 Station, we are on a mission to simplify your filling station's daily operations with practical software solutions that make sense in the real world. Our services are tailored to meet the genuine needs of station owners and managers. We provide tools that streamline your operations, enhance transparency, and boost profitability. From managing your inventory effectively to tracking sales seamlessly, our services are designed to empower your business to succeed in the most practical way.",
  },
  {
    title: "Financial Management & Reporting:",
    image: financial,
    content:
      "Get a crystal-clear view of your business's financial performance with our pragmatic financial management and reporting services. We provide daily analytic reports, including detailed records of expenses and sales tracking.etra erat.",
  },
  {
    title: "Staff Management & Efficiency:",
    image: staff,
    content:
      "Efficiently manage your staff and resources with our integrated staff management solutions. We understand that your workforce is the backbone of your operations, and our tools are designed to make your workforce more efficient and your management more straightforward. Keep track of salaries, maintain detailed records, and optimize workforce efficiency with real-world solutions that can genuinely make a difference.",
  },
];

export default data;
